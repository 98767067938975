.rdt_Table {
    box-shadow: rgba(0, 0, 0, 0.9) 0px 7px 29px 0px;
}

.picker {
    grid-column: 5/10;
    grid-row: 2/4;
    font-size: 80px;
  
  }

 .picker > input {
    text-align: center;
    border: none;
    background-color: transparent;
    border-bottom: 2px solid #E3B924;
  }

body {
    background: #f8fbfe !important;
}

span, p, h1, h2, h3, h4, h5, div, a {
    font-family: nunito, sans-serif;
}

.link {
    text-decoration: none;
    color: #8391a2;
}

.link:hover {
    color: #f0f3f9;
}

.rdt_TableCell {
    display: none;
}