.app {
    vertical-align: baseline;
    height: 100vh;
}

.error {
    color: red;
}

.overview {
    
}

.header2 {
    font-size: 16px;
    margin-left: 10px;
    text-transform: uppercase;
    color: rgb(56, 56, 56);
}

.card {
    background: white;
    padding: 30px 40px;
    box-shadow: rgb(0 0 0 / 10%) 0px 5px 5px -3px;
    margin-right: 20px;
    flex: 1;
    border-radius: 10px;
    border-left: 5px solid rgb(47, 18, 0);
}

.card:last-of-type {
    margin-right: 0;
}

.cardContainer {
    display: flex;
    margin: 25px 10px 25px 10px;
}

.cardHeader {
    margin: 0;
    margin-bottom: 3px;
    font-size: 14px;
    color: rgb(56, 56, 56);
    text-transform: uppercase;
}

.tableContainer {

}

.cardData {
    font-size: 24px;
    display: block;
}

.cardSubData {
    display: block;
    font-size: 12px;
    text-transform: uppercase;
}

.headerName {
    text-transform: uppercase;
    margin-left: 20px;
    font-weight: 600;
    letter-spacing: 2px;
    color: rgb(146, 136, 136);
    font-size: 24px;
}

.signInTitle {
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 2px;
    color: #313a46;
    font-size: 24px;
    margin-top: 30px;
    text-align: center;
    margin-bottom: 30px;
}


.dateContainer {
    color: white;
}

.dateContainer > div {
    width: unset;
}

.dateContainer > div > div > input {
    background: rgb(57, 45, 38);
    color: white;
    border-radius: 5px;
    padding: 5px;
    font-size: 18px;
    text-align: center;
    outline: none;
    border: none;
}

.signIn {
    width: 33%;
    min-width: 500px;
    max-width: 1000px;
}

.signInContainer {
    display: flex;
    align-items: center;
    justify-content: center;
}

.signInButton {
    width: 100%;
    outline: none;
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    background: #313a46;
    color: white;
    font-size: 20px;

}

.signInInputContainer {
    margin: 0 0 20px 0;
    width: 100%;
}

.signInInputContainer > input {
    width: 100%;
   box-sizing: border-box;
    font-size: 23px;
    border: none;
    border-bottom: 1px solid rgb(222, 222, 222);
    padding: 10px 20px;
    background: #f0f3f9 !important;
    outline: none;
}

.picker {
    outline: none;
    border: none;
    text-align: center;
    display: inline-block;
    font-size: 18px;
    width: 150px;
    min-width: none;
    padding: 5px 5px;
    background: #f0f3f9;
    border-radius: 4px;
    color: rgb(108, 117, 125);
    font-weight: 600;
    cursor: pointer;
}

.pickerWrapper {
}

.pickerMiddle {
    margin: 0 10px;
    transform: translateY(-8px);
    font-size: 30px;
    color: #ced1d4;
    height: 3px;
}

.pickerContainer {
    display: flex;
    justify-content: flex-end;
}

.mainContainer {
    display: inline-block;
    background: #f8fbfe;
    overflow-y: scroll;
    height: 100vh;
}

.sidebar {
    display: inline-block;
    background: #313a46;
    height: 100vh;
    vertical-align: top;
    color: white;
}

.commonContainer {
    padding: 20px 15px;
}

.pageTitle {
    font-size: 18px;
    font-weight: 700;
    color: #313a46;
}

.sidebarTitle {
    text-align: center;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 18px;
    color: #969ea8;
}

.header {
    height: 70px;
    background: #fff;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0 20px;
}

.headerSelectNote {
    font-size: 13px;
    max-width: 120px;
    margin-right: 20px;
    line-height: 85%;
    color: rgb(108, 117, 125);
}

.sidebarTitleContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 70px;
}

.sidebarItem {
    padding: 10px 0 10px 40px;
}

.sidebarItemsContainer {
    margin-top: 50px;
}

.signOutButton {
    color: rgb(163, 83, 83);
    position: fixed;
    bottom: 0;
    height: 70px;
    display: flex;
    align-items: center;
    padding-left: 40px;
}

.signInBox {
    height: 50vh;
    max-height: 400px;
    padding: 30px 20px 30px 20px;
    background: white;
    box-shadow: rgb(0 0 0 / 10%) 0px 5px 5px -3px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.signInCTA {
    text-align: center;
    font-weight: 700;
    font-size: 18px;
    color: rgb(108, 117, 125);
    margin-bottom: 20px;
}

.providerSelectContainer {
    margin-right: 20px;
    cursor: pointer;
}

.tableInputContainer {
    width: 80px;
    background: #f0f3f9;
    border-radius: 5px;
    font-size: 18px;
    color: rgb(108, 117, 125);
    padding-left: 15px;
    padding-right: 5px;
    white-space: nowrap;
}

.tableInputContainer > input {
    color: rgb(108, 117, 125);
    background: transparent;
    border: none;
    width: 40px;
    outline: none;
    border-radius: 5px;
    display: inline-block;
    padding: 5px 0 5px 5px;
    font-size: 18px;
    -moz-appearance: textfield;
}

.tableInputContainer > input::-webkit-outer-spin-button,
.tableInputContainer > input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
